import React from 'react';
// import { Card } from "antd";
import "./home.css"
import Logo from "./Logo";

const Home = () => {
  const logoSize = 70;
  return (
    <div>
      <div style={{ padding: 40, backgroundColor: 'black', width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ marginBottom: 80, display: 'flex', gap: 20, alignItems: 'end', flexDirection: window.innerWidth > 960 ? 'row' : 'column' }}>
          <Logo width={logoSize} height={logoSize} />
          <h1
            style={{
              color: 'white',
              fontWeight: '200',
              fontSize: '54px',
              margin: 0
            }}
          >
            Turning bold visions into reality
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Home;